// import ModuleName from './path/to/file'

import Rellax from 'rellax';
import AOS from 'aos';
import '../../node_modules/aos/dist/aos.css';
AOS.init({
    duration: 700,
});


var rellax = new Rellax('.rellax');





var swiper = new Swiper(".swiper", {
    slidesPerView: 2,
    slidesPerGroup: 2,
    grid: {
        rows: 2,
        fill: "row"
    },
    spaceBetween: 30,

    // Responsive breakpoints
    breakpoints: {

        640: {
            slidesPerView: 4,
            slidesPerGroup: 4,
            grid: {
                rows: 2,
                fill: "row"
            },
            spaceBetween: 60,
        }
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    on: {
        afterInit: function() {
            // const scroll = new LocomotiveScroll({
            //     el: document.querySelector('[data-scroll-container]'),
            //     smooth: true
            // });
            // setTimeout(function() {
            //     scroll.update()
            //     console.log('timeout');
            // }, 5000);
        },
    },
});